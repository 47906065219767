<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="複製類型" field="CopyType" :item-render="{}" span="24">
          <template #default="{ data }">
            <vxe-select v-model="data.CopyType" placeholder="請選擇複製類型" clearable>
              <vxe-option v-for="(item) in Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } })" :key="'flow-type' + item.value" :value="item.value" :label="item.label"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="目標類型" field="Type" :item-render="{}" span="24">
          <template #default="{ data }">
            <vxe-select v-model="data.Type" placeholder="請選擇目標類型" clearable>
              <vxe-option v-for="(item) in Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } })" :key="'flow-type' + item.value" :value="item.value" :label="item.label"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" content="複製">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Type', order: 'asc' }, { field: "Level", order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      save() {
        if (table.formData.Type != null && table.formData.CopyType != null && table.formData.Type >= 0 && table.formData.CopyType >= 0) {
          emit('save', { type: table.formData.Type, copyType: table.formData.CopyType });
        }
      },
      rules: {
        CopyType: [{ required: true }],
        Type: [{ required: true }],
      }
    })

    watch(() => props.visible, async(value) => {
      if(value) {
        table.formData = {} as any
      }      
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
