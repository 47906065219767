<template>
  <vxe-button class="m-2" type="button" status="primary" @click="table.submit" content="儲存">
  </vxe-button>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="名稱" field="Name" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Name" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="類型" field="Type" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Type" placeholder="請選擇類型" clearable>
              <vxe-option v-for="(item) in Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } })" :key="'flow-type' + item.value" :value="item.value" :label="item.label"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="層級" field="Level" :item-render="{}" span="4">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Level" placeholder="層級" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="職務" field="PositionId" :item-render="{}" span="5">
          <template #default="{ data }">
            <select-box v-bind="selectorOptions" v-model="data.PositionId" />
          </template>
        </vxe-form-item>
        <vxe-form-item title="僅通知" field="NotifyOnly" :item-render="{}" span="5">
          <template #default="{ data }">
            <vxe-switch v-model="data.NotifyOnly" placeholder="僅通知" clearable></vxe-switch>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" content="確認">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Name" title="名稱">
      </vxe-column>
      <vxe-column field="Type" title="表單類型" sortable :formatter="({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : ''">
      </vxe-column>
      <vxe-column field="Level" title="層級" sortable>
      </vxe-column>
      <vxe-column field="Position" title="職務">
        <template #default="{ row }">
          <span v-if="row.Position">{{ row.Position.Name }}</span>
        </template>
      </vxe-column>
      <vxe-column v-if="!readonly" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <!-- <vxe-button @click="table.update(row)">編輯</vxe-button> -->
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Type', order: 'asc' }, { field: "Level", order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      async save() {
        if (table.formData.PositionId) {
          const temp = await model?.dispatch("position/find", table.formData.PositionId)
          table.formData.Position = { Id: temp.Id, Name: temp.Name };
        }

        await grid.value.insert(table.formData);
        grid.value.sort([{ field: "Type", order: 'asc' }, { field: "Level", order: 'asc' }]) // .sort();
        
        table.formData.Name = "";
        table.formData.Level = null;
        table.formData.PositionId = null;
      },
      rules: {
        Name: [{ required: true }],
        Type: [{ required: true }],
        Level: [{ required: true }],
        PositionId: [{ required: true }],
      }
    })

    watch(() => props.visible, async(value) => {
      if(value) {
        table.data = [] as any;
        table.formData = {} as any
      }      
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
