
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import FlowTable from "@/components/document-flow/FlowTable.vue";
import CopyTable from "@/components/document-flow/CopyTable.vue";
import DeleteTable from "@/components/document-flow/DeleteTable.vue";
export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FlowTable,
    CopyTable,
    DeleteTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref("");

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("DocumentFlowCreate");
    const canUpdate = checkPermissions("DocumentFlowUpdate");
    const canDelete = checkPermissions("DocumentFlowDelete");

    const gridOptions: GridOptions = {
      id: 'documentFlow',
      title: '文件流程定義',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Level', title: '層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Position.Name', title: '職務', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "NotifyOnly", title: "僅通知", showHeaderOverflow: true, showOverflow: true, resizable: false, width: 100, cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } } },
      ],
      promises: {
        query: model ? async (params) => {
          if (selectedType.value !== "") {
            params.condition!.and("Type", Operator.Equal, selectedType.value);
          }
          if (params?.sortings?.length === 0) {
            params.sortings = [{ column: 'Type', order: 0 }, { column: 'Level', order: 0 }, { column: "CreatedTime", order: 0 }];
          }
          return await model.dispatch('documentFlow/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('documentFlow/query') : undefined,
        save: model
          ? (params) => model.dispatch('documentFlow/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Type',
          title: '表單類型',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Level',
          title: '層級',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'number' },
          }
        },
        {
          field: 'PositionId',
          title: '職務',
          span: 12,
          slots: { default: "column-position" }
        },
        { field: "NotifyOnly", title: "僅通知", span: 12, itemRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否' } } },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Level: [{ required: true }],
        Type: [{ required: true }],
        PositionId: [{ required: true }],
      }
    }

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 2 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const flowGrid = ref<any>()
    const flowModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show() {
        flowModal.visible = true;
      },
      async save(data: any) {
        if (data && data.length > 0) {
          try {
            var insertRows:any = data;
            await model?.dispatch('documentFlow/save', { insertRows })
            flowModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認資料內容' })
        }
      }
    })

    const copyGrid = ref<any>()
    const copyModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show() {
        copyModal.visible = true;
      },
      async save({ type, copyType }: any) {
        if (type !== copyType) {
          try {
            await model?.dispatch('documentFlow/copy', { type, copyType })
            copyModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '複製與目標類型不得相同' })
        }
      }
    })

    const deleteGrid = ref<any>()
    const deleteModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show() {
        deleteModal.visible = true;
      },
      async save({ type }: any) {
        if (type >= 0) {
          try {
            await model?.dispatch('documentFlow/deleteAll', { type })
            deleteModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認刪除類型' })
        }
      }
    })

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("position/query", params) // eslint-disable-line
        }
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      selectorOptions,
      selectedType,
      model,
      flowGrid,
      flowModal,
      fileGrid,
      fileModal,
      copyGrid,
      copyModal,
      deleteGrid,
      deleteModal,
      canCreate,
      canUpdate,
      canDelete,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
