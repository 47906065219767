
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Type', order: 'asc' }, { field: "Level", order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      async save() {
        if (table.formData.PositionId) {
          const temp = await model?.dispatch("position/find", table.formData.PositionId)
          table.formData.Position = { Id: temp.Id, Name: temp.Name };
        }

        await grid.value.insert(table.formData);
        grid.value.sort([{ field: "Type", order: 'asc' }, { field: "Level", order: 'asc' }]) // .sort();
        
        table.formData.Name = "";
        table.formData.Level = null;
        table.formData.PositionId = null;
      },
      rules: {
        Name: [{ required: true }],
        Type: [{ required: true }],
        Level: [{ required: true }],
        PositionId: [{ required: true }],
      }
    })

    watch(() => props.visible, async(value) => {
      if(value) {
        table.data = [] as any;
        table.formData = {} as any
      }      
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
