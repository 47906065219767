<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">文件流程定義</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button v-show="!grid.isEditing && canCreate" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          單筆新增
        </button>
        <button v-show="!grid.isEditing && canCreate" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="flowModal.show">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          多筆新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <select
              v-model="selectedType"
              @change="grid.refresh"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border mr-2"
            >
              <option value="">選擇表單類型</option>
              <option
                v-for="(item, index) in Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } })"
                :key="'type' + index" :label="item.label" :value="item.value" class="py-3"></option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
          <div v-if="canCreate" class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full ml-2 sm:w-20 bg-theme-1 text-white"
              @click="copyModal.show"
            >
              複製流程
            </button>
          </div>
          <div v-if="canDelete" class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full ml-2 sm:w-20 bg-theme-1 text-white"
              @click="deleteModal.show"
            >
              刪除流程
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-position="{ data }">
                <select-box v-bind="selectorOptions" v-model="data.PositionId" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent($event, column, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent($event, column, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal v-model="flowModal.visible" width="60%" height="500" title="新增文件流程定義" show-zoom="true">
      <FlowTable ref="flowGrid" :visible="flowModal.visible" @submit="flowModal.save" />
    </vxe-modal>
    <vxe-modal v-model="copyModal.visible" width="30%" height="350" title="複製文件流程定義" show-zoom="true">
      <CopyTable ref="copyGrid" :visible="copyModal.visible" @save="copyModal.save" />
    </vxe-modal>
    <vxe-modal v-model="deleteModal.visible" width="30%" height="350" title="刪除文件流程定義" show-zoom="true">
      <DeleteTable ref="deleteGrid" :visible="deleteModal.visible" @save="deleteModal.save" />
    </vxe-modal>
  </div>
</template>

<script lang="ts">
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import FlowTable from "@/components/document-flow/FlowTable.vue";
import CopyTable from "@/components/document-flow/CopyTable.vue";
import DeleteTable from "@/components/document-flow/DeleteTable.vue";
export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FlowTable,
    CopyTable,
    DeleteTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref("");

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("DocumentFlowCreate");
    const canUpdate = checkPermissions("DocumentFlowUpdate");
    const canDelete = checkPermissions("DocumentFlowDelete");

    const gridOptions: GridOptions = {
      id: 'documentFlow',
      title: '文件流程定義',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Level', title: '層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Position.Name', title: '職務', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "NotifyOnly", title: "僅通知", showHeaderOverflow: true, showOverflow: true, resizable: false, width: 100, cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } } },
      ],
      promises: {
        query: model ? async (params) => {
          if (selectedType.value !== "") {
            params.condition!.and("Type", Operator.Equal, selectedType.value);
          }
          if (params?.sortings?.length === 0) {
            params.sortings = [{ column: 'Type', order: 0 }, { column: 'Level', order: 0 }, { column: "CreatedTime", order: 0 }];
          }
          return await model.dispatch('documentFlow/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('documentFlow/query') : undefined,
        save: model
          ? (params) => model.dispatch('documentFlow/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Type',
          title: '表單類型',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Level',
          title: '層級',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'number' },
          }
        },
        {
          field: 'PositionId',
          title: '職務',
          span: 12,
          slots: { default: "column-position" }
        },
        { field: "NotifyOnly", title: "僅通知", span: 12, itemRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否' } } },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Level: [{ required: true }],
        Type: [{ required: true }],
        PositionId: [{ required: true }],
      }
    }

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 2 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const flowGrid = ref<any>()
    const flowModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show() {
        flowModal.visible = true;
      },
      async save(data: any) {
        if (data && data.length > 0) {
          try {
            var insertRows:any = data;
            await model?.dispatch('documentFlow/save', { insertRows })
            flowModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認資料內容' })
        }
      }
    })

    const copyGrid = ref<any>()
    const copyModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show() {
        copyModal.visible = true;
      },
      async save({ type, copyType }: any) {
        if (type !== copyType) {
          try {
            await model?.dispatch('documentFlow/copy', { type, copyType })
            copyModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '複製與目標類型不得相同' })
        }
      }
    })

    const deleteGrid = ref<any>()
    const deleteModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show() {
        deleteModal.visible = true;
      },
      async save({ type }: any) {
        if (type >= 0) {
          try {
            await model?.dispatch('documentFlow/deleteAll', { type })
            deleteModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認刪除類型' })
        }
      }
    })

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("position/query", params) // eslint-disable-line
        }
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      selectorOptions,
      selectedType,
      model,
      flowGrid,
      flowModal,
      fileGrid,
      fileModal,
      copyGrid,
      copyModal,
      deleteGrid,
      deleteModal,
      canCreate,
      canUpdate,
      canDelete,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
